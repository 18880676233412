import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { hexToRGBA } from '@ecommerce/shared'
import { Icon, IconProps } from '../Icon/Icon'

const cssPrefix = `MenuTileAccount__`

export const tileBoxStyles = css`
  border-bottom: 2px solid ${(props) => hexToRGBA(props.theme.colors.black20, 0.1)};
  display: flex;
  cursor: pointer;
  overflow: hidden;
  padding: 15px 0 15px 19px;
  justify-content: flex-start;
  align-items: center;
`

const Tile = styled.div`
  ${tileBoxStyles}
  div {
    display: flex;
  }
  .${cssPrefix} {
    &tile-text {
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 15px;
      height: fit-content;
    }
  }
`
export interface MenuTileProps {
  iconId: IconProps['iconId']
  text: string
  actionType?: string
  slug?: string
}

export const MenuTileAccount = (props: MenuTileProps & HTMLAttributes<HTMLDivElement>) => (
  <Tile {...props}>
    <Icon iconId={props.iconId} size="25" className={`${cssPrefix}icon`} />
    <span className={`${cssPrefix}tile-text`}>{props.text}</span>
    {props.actionType === 'category' && <Icon iconId="chevron_right" size="25" className={`${cssPrefix}icon`} />}
  </Tile>
)
