import React, { useEffect } from 'react'
import { toCssPrefix, breakpoints, useResolution, useAuth, Button, Image, useShoppingCart } from '@ecommerce/shared'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { MyFavoritesPage as Props } from '../types/PgPages'
import Layout from '../components/Layout'
import { getNavBar } from '../components/MyAccount/CustomNavBar'
import AccountLayout from '../templates/UserMenu'
import useContentfulGlobal from '../hooks/useContentfulGlobal'
import { sendPageViewEvent } from '../utils/events'

const { cssPrefix, toPrefix } = toCssPrefix('MyInvoices__')

const Wrapper = styled.div`
  .UserMenu__menu {
    margin-top: 79px;
  }

  .${cssPrefix} {
    &content {
      min-height: 350px;
      position: relative;
    }

    &loader {
      width: 84px;
      height: 84px;
      position: absolute;
      left: calc(50% - 42px);
      top: calc(50% - 42px);
    }

    &title {
      width: 335px;
      padding-bottom: 30px;
      margin: 0 auto 25px;
      text-align: left;
    }

    &top-bar {
      button {
        position: absolute;
        right: 0;
        top: 20px;
      }
    }

    &grid {
      display: grid;
      gap: 24px;
      place-content: center;
    }

    &content-body {
      max-width: 333px;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      margin: 0 auto;
    }

    &icon {
      width: 93px;
      height: 93px;
      object-fit: contain;
      margin: 0 auto 28px;
      display: block;
    }

    &heading {
      font-size: 18px;
      line-height: 22px;
      margin: 0 0 32px;
    }

    &action-button {
      width: 204px;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &grid {
        grid-template-columns: repeat(2, 1fr);
      }

      &title {
        text-align: center;
      }

      &content-body {
        max-width: 442px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &title {
        width: 100%;
        text-align: left;
        border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
      }

      &grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
      }
      &content-body {
        max-width: 442px;
      }
    }
  }
`

const MyInvoicesPage = ({ pageContext: { productCategories }, data }: Props) => {
  const { isDesktop } = useResolution()
  const { getConnectifCart } = useShoppingCart()
  const {
    state: { firstName, lastName },
    getEntityInfo,
  } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Mis facturas' })
  }, [])

  const {
    invoiceImage: {
      file: { url },
    },
    invoiceUrl,
  } = useContentfulGlobal()

  const NavBar = isDesktop
    ? undefined
    : () => getNavBar({ slug: '../', onLogoClick: () => navigate(`/`), categories: productCategories })

  return (
    <Layout navbar={NavBar} title="Mis facturas" categories={productCategories}>
      <Wrapper className={cssPrefix}>
        <AccountLayout
          className={cssPrefix}
          customerName={`${firstName} ${lastName}`}
          title="Mis Facturas"
          titleClassName={toPrefix('title')}
          contentClassName={toPrefix('content')}
          topBarClassName={toPrefix('top-bar')}
          activeMenuIndex={5}
        >
          <div className={toPrefix('content-body')}>
            <Image className={toPrefix('icon')} alt="escoge-tus-productos" src={url} />
            <p className={toPrefix('heading')}>
              Después de realizar tu pedido, puedes revisar la información con el detalle de tu factura.
            </p>
            <Button
              isDisabled={false}
              onClick={() => window.open(`${invoiceUrl}`, '_blank')}
              className={toPrefix('action-button')}
            >
              Ver Mis Facturas
            </Button>
          </div>
        </AccountLayout>
      </Wrapper>
    </Layout>
  )
}

export default MyInvoicesPage
