import React, { useState } from 'react'
import styled from 'styled-components'
import { Image, Anchor, NavBar, capitalize, getAuth, useAuth, useLocation, Market } from '@ecommerce/shared'
import { navigate } from 'gatsby'
import { Icon } from '../Icon/Icon'
import MyAccountSidebar from '../MyAccountSidebar/MyAccountSidebar'
import { FlatLocationProductCategory } from '../../types/PgPages'
import { imgUrlCL, imgUrlBO } from '../Layout'
import ConfirmationAlert from '../ConfirmationAlert'
import useSession from '../../hooks/useSession'
import MenuMobile from '../MenuMobile/MenuMobile'

const CenterWrapper = styled.div`
  .img-logo {
    height: 50px;
    object-fit: contain;
  }
`
const LeftWrapper = styled(Anchor)`
  .burguer-menu {
    fill: white;
  }
  .initials {
    color: ${({ theme }) => theme.colors.background.layout};
    background: ${({ theme }) => theme.colors.white};
    font-size: 20px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    margin-top: -3px;

    span {
      text-transform: uppercase;
    }
  }
`

const center = (onLogoClick?: () => void) => (
  <CenterWrapper>
    <Anchor onClick={() => (onLogoClick ? onLogoClick() : null)}>
      <Image
        src="https://images.ctfassets.net/16npdkkoi5mj/4EAocVvRjUs3wHYN7PiSDB/f89865bb82569f8a649d1c07fbfbfbd5/CCO_-_BLANCO_-_SVG.svg"
        alt="logo"
        className="img-logo"
      />
    </Anchor>
  </CenterWrapper>
)

const CustomNavbar = ({
  navigatePath = '../',
  onLogoClick,
  currentMarket,
  categories,
}: {
  navigatePath: string
  onLogoClick?: () => void
  currentMarket?: Market
  categories?: FlatLocationProductCategory[]
}) => {
  const isAuth = getAuth()
  const [showMenu, setShowMenu] = useState(false)
  const { isBolivia, textByCountry } = useLocation()
  const { resetAppState } = useSession()

  const isBO = isBolivia()
  const {
    state: { firstName, lastName },
  } = useAuth()

  const [isOpenMyAccount, setIsOpenMyAccount] = useState(false)
  const [isOpenSignoutModal, setIsOpenSignoutModal] = useState(false)

  const onSignOut = async () => {
    setIsOpenSignoutModal(false)
    setIsOpenMyAccount(false)

    await resetAppState('/')
  }

  const userMenu = (
    <LeftWrapper
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        setIsOpenMyAccount(!isOpenMyAccount)
      }}
    >
      <span className="initials">
        <span>{firstName.slice(0, 1).toUpperCase()}</span>
      </span>
    </LeftWrapper>
  )

  const burguerMenu = (
    <LeftWrapper
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        setShowMenu(!showMenu)
      }}
    >
      <Icon onClick={() => setShowMenu(!showMenu)} size="26" iconId="menu" className="burguer-menu" />
    </LeftWrapper>
  )

  return (
    <>
      <NavBar left={categories ? burguerMenu : userMenu} center={center(onLogoClick)} />
      {isOpenMyAccount && isAuth && (
        <MyAccountSidebar
          isRight={false}
          customerName={`${capitalize(firstName)} ${capitalize(lastName)}`}
          logoUrl={isBO ? imgUrlBO : imgUrlCL}
          onClose={() => setIsOpenMyAccount(false)}
          onBlur={() => setIsOpenMyAccount(false)}
          onSignOut={() => setIsOpenSignoutModal(true)}
          onAccountClick={() => navigate(currentMarket && currentMarket.slug ? `/my-account` : '/')}
          onNavigationClick={(slug) => navigate(currentMarket && currentMarket.slug ? `/${slug}` : '/')}
          currentCity={currentMarket ? capitalize(currentMarket.name) : ''}
        />
      )}
      {isOpenSignoutModal && isAuth && (
        <ConfirmationAlert
          onClose={() => setIsOpenSignoutModal(false)}
          onBlur={() => setIsOpenSignoutModal(false)}
          onConfirm={onSignOut}
          confirmButtonText="Cerrar sesión"
          text="¿Estás seguro de querer cerrar tu sesión?"
        />
      )}
      {showMenu && (
        <MenuMobile
          onClose={() => setShowMenu(false)}
          onBlur={() => setShowMenu(false)}
          logoUrl={isBO ? imgUrlBO : imgUrlCL}
          onNavigationClick={(slug) => currentMarket && navigate(`/${currentMarket.slug}/${slug}`)}
          categories={categories}
          onSignOut={onSignOut}
        />
      )}
    </>
  )
}

type Props = {
  slug: string
  onLogoClick?: () => void
  currentMarket?: Market
  categories?: FlatLocationProductCategory[]
}

export const getNavBar = ({ slug, onLogoClick, currentMarket, categories }: Props) => (
  <CustomNavbar navigatePath={slug} onLogoClick={onLogoClick} currentMarket={currentMarket} categories={categories} />
)

export default CustomNavbar
